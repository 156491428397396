const imageCropper = (() => {
// Crop for club (avatar & header)
    let avatarCropWidth = 293;
    let avatarCropHeight = 293;
    let headerCropWidth = 1920;
    let headerCropHeight = 492;

    var clubAvatar = function (input) {
        let $avatar = $('.club-detail__avatar > img');
        loadLocalImage(input, $avatar, 'avatar');
    };

    var clubHeader = function (input) {
        let $header = $('.club-detail__main-image-wrapper > img');
        loadLocalImage(input, $header, 'header');
    };

    var loadLocalImage = function (input, $element, type) {
        if (input.files && input.files[0]) {
            let reader = new FileReader();

            reader.onload = function (e) {
                $element.attr('src', e.target.result);
            };

            reader.readAsDataURL(input.files[0]);

            $element.on('load', function () {
                cropper($element, type);
            });
        }
    };

    let cropper = function ($element, type) {
        if (type === 'avatar') {
            $element.guillotine({
                width: avatarCropWidth,
                height: avatarCropHeight,
                eventOnChange: 'guillotinechange'
            });
            $('#avatar-zoom-in').on('click', function (evt) {
                evt.preventDefault();
                $element.guillotine('zoomIn');
            });

            $('#avatar-zoom-out').on('click', function (evt) {
                evt.preventDefault();
                $element.guillotine('zoomOut');
            });
            $element.on('guillotinechange', function (ev, data, action) {
                $('input[name="crop-props"]').val(JSON.stringify(data));
            });
        } else {
            $element.guillotine({
                width: headerCropWidth,
                height: headerCropHeight,
                eventOnChange: 'guillotinechange'
            });
            $('#header-zoom-in').on('click', function (evt) {
                evt.preventDefault();
                $element.guillotine('zoomIn');
            });

            $('#header-zoom-out').on('click', function (evt) {
                evt.preventDefault();
                $element.guillotine('zoomOut');
            });
            $element.on('guillotinechange', function (ev, data, action) {
                $('input[name="crop-props"]').val(JSON.stringify(data));
            });
        }
        $element.guillotine('center');
    };

    $(document).on('change', '#avatar', function () {
        clubAvatar(this);
    });

    $(document).on('change', '#header', function () {
        clubHeader(this);
    });


// Image Preview

    let previewfiles;

// function handleFileRemove(e) {
//     console.log(previewfiles);
//     delete previewfiles[$(e.target).attr('data-id')];
//     console.log(previewfiles);
// }

    // restrict number of file upload.
    $("#club_image").on("change", function () {
        if ($("#club_image")[0].files.length > 5) {
            alert("Sie können nur 5 Bilder auswählen");
            return false;
        } else {
            return true;
        }
    });

    function handleFileSelect(event) {
        //Check File API support
        if (window.File && window.FileList && window.FileReader) {
            previewfiles = Array.prototype.slice.call(event.target.files); //FileList object
            var $output = $(this).closest('[data-upload-result]').find('[id^="upload-result"]');
            var $newImageText = $(this).closest('[data-upload-result]').find('[data-new-image-text]');
            $output.html('');
            $newImageText.removeClass('hidden');

            for (var i = 0; i < previewfiles.length; i++) {
                var file = previewfiles[i];
                if (!file.type.match('image')) continue;
                (function (i) {
                    //Only pics

                    var picReader = new FileReader();
                    picReader.addEventListener("load", function (event) {
                        var picFile = event.target;
                        var div = document.createElement("div");
                        $(div).addClass('thumbnail__container');
                        // $(div).addClass('thumbnail__container--deleteable');
                        $(div).attr('data-id', i);
                        div.innerHTML = "<img class='thumbnail__image' src='" + picFile.result + "'/>";
                        $output.prepend(div);
                    });
                    //Read the image
                    picReader.readAsDataURL(file);
                })(i);
            }
        } else {
            console.log("Your browser does not support File API");
        }
    }

    function handleFileSelectWithTitle(event) {
        //Check File API support
        if (window.File && window.FileList && window.FileReader) {
            previewfiles = Array.prototype.slice.call(event.target.files); //FileList object
            var $output = $(this).closest('[data-upload-result]').find('[class^="upload-result"]');
            var $newImageText = $(this).closest('[data-upload-result]').find('[data-new-image-text]');
            $output.html('');
            $newImageText.removeClass('hidden');

            for (var i = 0; i < previewfiles.length; i++) {
                var file = previewfiles[i];
                if (!file.type.match('image')) continue;
                (function (i) {
                    //Only pics

                    var picReader = new FileReader();
                    picReader.addEventListener("load", function (event) {
                        var picFile = event.target;						
                        var div = document.createElement("div");
                        $(div).addClass('row');
                        var html = "<div class='col-md-4'><img class='thumbnail__image thumbnail__container' src='" + picFile.result + "'/></div>"
                            + "<div class='col-md-8'><div class='form-group'><input type='text' class='input' placeholder='Bild Titel' name='imgTitle[]'></div><div class='form-group' style='padding-top:20px;'><textarea class='input' name='imgDesc[]'></textarea></div></div>";

                        div.innerHTML = html;
                        $output.prepend(div);
                    });
                    //Read the image
                    picReader.readAsDataURL(file);
                })(i);
            }
        } else {
            console.log("Your browser does not support File API");
        }
    } 	

// Event listener
    $(document).on('change', '[id^="image"]', handleFileSelect);
    $(document).on('change', '[id^="event_image"]', handleFileSelect);
    $(document).on('change', '[id^="achievement_picture"]', handleFileSelect);
    $(document).on('change', '[id^="team_picture"]', handleFileSelect);
    $(document).on('change', '[id^="user_post_image"]', handleFileSelect);
    $(document).on('change', '#main_image', handleFileSelect);
    $(document).on('change', '#logo_premium_partner', handleFileSelect);
    $(document).on('change', '[data-previewable-image-upload]', handleFileSelect);
// $(document).on('click', '.thumbnail__container', handleFileRemove);
    $(document).on('change', '[id^="club_logo"]', handleFileSelect);
    $(document).on('change', '[id^="project_image"]', handleFileSelect);
    $(document).on('change', '[id^="projectdesc_image"]', handleFileSelect);
    $(document).on('change', '[id^="club_image"]', handleFileSelect);
    $(document).on('change', '[id^="news_image"]', handleFileSelectWithTitle);

    $('.club-post__image-slider').slick({
        prevArrow: '<button type="button" class="slick-prev"></button>',
        nextArrow: '<button type="button" class="slick-next"></button>',
        autoplay: false,
        arrows: true,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    autoplay: true,
                    arrows: false
                }
            }
        ]
    });
})();

export default imageCropper;
const networkSearch = (() => {

    $("input[name='user_type']").change(function () {
        var user_type = $('input[name=user_type]:checked').val();
        if (user_type == 'verbände')
            $('#network-search-area').hide();
        else
            $('#network-search-area').show();
    });

    var loader = '<div data-loader="" class="loader__wrapper loader__wrapper--centered">';
    loader += '<div class="loader loader--loading"></div>';
    loader += '<div class="loader loader--loading"></div>';
    loader += '</div>';

    $(document).ready(function () {
        $("#search").keyup(function () {
            var str = $("#search").val();
            var length = $("#search").val().length;
            var search_id = $("#search_id").val();
            var user_type = $('input[name=user_type]:checked').val();
            if (length > 2 || length == 0) {

                $("#" + user_type).html(loader);

                if (user_type == 'vereinen') {
                    $.get("../club/friend-list?user=" + user_type + "&club_id=" + search_id + "&searchString=" + str, function (data) {
                        $("#" + user_type).html(data['data']['rendered']);
                    });
                } else if (user_type == 'fan') {
                    $.get("../club/user-list?user=" + user_type + "&club_id=" + search_id + "&searchString=" + str, function (data) {
                        $("#" + user_type).html(data['data']['rendered']);
                    });
                }
                else if (user_type == 'member') {
                    $.get("../club/user-list?user=" + user_type + "&club_id=" + search_id + "&searchString=" + str, function (data) {
                        $("#" + user_type).html(data['data']['rendered']);
                    });
                } else if (user_type == 'old_member') {
                    $.get("../club/user-list?user=" + user_type + "&club_id=" + search_id + "&searchString=" + str, function (data) {
                        $("#" + user_type).html(data['data']['rendered']);
                    });
                } else if (user_type == 'b_subs') {
                    $.get("../verband/fan-list?bandage_id=" + search_id + "&searchString=" + str, function (data) {
                        $("#" + user_type).html(data['data']['rendered']);
                    });
                } else if (user_type == 'b_vereinen') {
                    $.get("../verband/vereinen-list?bandage_id=" + search_id + "&searchString=" + str, function (data) {
                        $("#" + user_type).html(data['data']['rendered']);
                    });
                } else if (user_type == 'friend') {
                    $.get("../user/friend-list?user_id=" + search_id + "&searchString=" + str, function (data) {
                        $("#" + user_type).html(data['data']['rendered']);
                    });
                } else if (user_type == 'u_vereine') {
                    $.get("../user/vereine-list?user_id=" + search_id + "&searchString=" + str, function (data) {
                        $("#" + user_type).html(data['data']['rendered']);
                    });
                } else if (user_type == 'u_verbände') {
                    $.get("../user/bandage-list?user_id=" + search_id + "&searchString=" + str, function (data) {
                        $("#" + user_type).html(data['data']['rendered']);
                    });
                } else {

                }
            }
        });
    });

    $(document).on('click', '#pagination a', function (event) {
        $('li').removeClass('active');
        $(this).parent('li').addClass('active');
        event.preventDefault();
        var myurl = $(this).attr('href');
        var user_type = $('input[name=user_type]:checked').val();
        getData(myurl, user_type, loader);
    });

    function getData(page, user_type, loader) {
        $("#" + user_type).empty().html(loader);

        $.get(page, function (data) {
            $("#" + user_type).empty().html(data['data']['rendered']);
        });
    }	

})();

export default networkSearch;
const newMember = (() => {
    let $component = $('[data-component="new-member"]');
    function initializeSlider() {
        $component.find('.slick').slick({
            prevArrow: '<button type="button" class="slick__btn-prev"></button>',
            nextArrow: '<button type="button" class="slick__btn-next"></button>',
            autoplay: false,
            arrows: true,
            slidesToShow: 3,
            responsive: [
                {
                    breakpoint: 992,
                    settings: {
                        autoplay: true,
                        arrows: false
                    }
                }
            ]
        });
    }

    function init() {
        if ($component) {
            initializeSlider();
        }
    }

    init();
})();

export default newMember;
const toTop = (() => {
    $(window).scroll(function () {
        if ($(this).scrollTop() > 100) {
            $('.to-top').removeClass('to-top--hidden');
        } else {
            $('.to-top').addClass('to-top--hidden');
        }
    });
    $('.to-top').click(function () {
        $('body,html').animate({
            scrollTop: 0
        }, 400);
        return false;
    });
})();

export default toTop;
const newsSlugHandler = (() => {
    let titleEl = document.getElementById('admin-news-title');
    if (titleEl) {
        titleEl.addEventListener('blur', (evt) => {
            let title = evt.target.value;
            if (title) {
                let slugEl = document.getElementById('admin-news-slug'),
                    slugDate = document.getElementById('admin-news-slug_date').value,
                    slugElOpacity = 0;
                let slug = title.toLowerCase().trim().replace(/ä/gi, 'ae').replace(/ü/gi, 'ue').replace(/ö/gi, 'oe').replace(/[.\s]/g, '-').replace(/ß/g, 'ss').replace(/\?/g, '');
                slugEl.value = `${slugDate}-${slug}`;
                if (slugEl.style.opacity === 0 || slugEl.style.display === 'none') {
                    slugEl.style.opacity = slugElOpacity;
                    slugEl.style.display = 'block';
                    let fadeSlugEl = setInterval(() => {
                        slugElOpacity += 0.1;
                        slugEl.style.opacity = slugElOpacity;
                        if (slugEl.style.opacity >= 1) {
                            clearInterval(fadeSlugEl);
                        }
                    }, 100);
                }
            }
        })
    }
})();

export default newsSlugHandler;
const autoCompletion = (() => {
    let $cityAutocomplete = $('.city-autocomplete');
    let $sportAutocomplete = $('.sport-autocomplete');
    let $clubAutocomplete = $('.club-autocomplete');
    let $userAutocomplete = $('.user-autocomplete');

    if ($cityAutocomplete) {
        $cityAutocomplete.each(function () {
            var input = $(this);
            var cities = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                //prefetch: '../data/films/post_1960.json',
                remote        : {
                    url      : $(this).data('cities-url') + '?search=%QUERY',
                    wildcard : '%QUERY',
                    transform: function (response) {
                        //return $.map(response.data, function(el, i) {
                        //    return el.name;
                        //});
                        return cities.sorter(response.data);
                    }
                },
                sorter        : function (a, b) {
                    var input_string = input.val();
                    return Levenshtein.get(a.name, input_string) - Levenshtein.get(b.name, input_string);
                }
            });

            $(this).typeahead(null, {
                name   : 'city',
                display: 'name',
                source : cities,
                limit  : 20
            });
        });
    }

    if ($sportAutocomplete) {
        $sportAutocomplete.each(function () {
            var input = $(this);
            var sports = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,
                //prefetch: '../data/films/post_1960.json',
                remote        : {
                    url      : $(this).data('sports-url') + '?search=%QUERY',
                    wildcard : '%QUERY',
                    transform: function (response) {
                        //return $.map(response.data, function(el, i) {
                        //    return el.name;
                        //});
                        return sports.sorter(response.data);
                    }
                },
                sorter        : function (a, b) {
                    var input_string = input.val();
                    return Levenshtein.get(a.title, input_string) - Levenshtein.get(b.title, input_string);
                }
            });

            $(this).typeahead(null, {
                name   : 'sport',
                display: 'title',
                source : sports,
                limit  : 20
            });
        });
    }

    if ($clubAutocomplete) {
        $clubAutocomplete.each(function () {
            var input = $(this);
            var myElement = document.getElementById('federal_state'),
                federal_state = myElement.value;
            var clubs = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,

                remote: {
                    url      : $(this).data('clubs-url') + '?federal_state=' + federal_state + '&search=%QUERY',
                    wildcard : '%QUERY',
                    transform: function (response) {
                        return clubs.sorter(response.data);
                    }
                },
                sorter: function (a, b) {
                    var input_string = input.val();
                    return Levenshtein.get(a.name, input_string) - Levenshtein.get(b.name, input_string);
                }
            });


            $(this).typeahead(null, {
                name   : 'club',
                display: 'name',
                source : clubs,
                limit  : 20
            }).bind('typeahead:select', function (ev, suggestion) {
                $('#club_id').val(suggestion.id);
            });
        });
    }

    if ($userAutocomplete) {
        $userAutocomplete.each(function () {
            var input = $(this);
            var users = new Bloodhound({
                datumTokenizer: Bloodhound.tokenizers.obj.whitespace('value'),
                queryTokenizer: Bloodhound.tokenizers.whitespace,

                remote: {
                    url      : $(this).data('users-url') + '?search=%QUERY',
                    wildcard : '%QUERY',
                    transform: function (response) {
                        return users.sorter(response.data);
                    }
                },
                sorter: function (a, b) {
                    var input_string = input.val();
                    return Levenshtein.get(a.username, input_string) - Levenshtein.get(b.username, input_string);
                }
            });

            $(this).typeahead(null, {
                name   : 'user',
                display: 'username',
                source : users,
                limit  : 20
            }).bind('typeahead:select', function (ev, suggestion) {
                $('#username').val(suggestion.username);
                console.log(ev);
            });
        });
    }
})();

export default autoCompletion;
const datepickerInitializer = (() => {
    $(document).on('focus', '[data-datetimepicker-enabled]', function () {
        if (!$(this).hasClass('hasDatepicker')) {
            $(this).datetimepicker({
                useCurrent: true,
                showTodayButton: true,
                showClear: true,
                showClose: true,
            });
            $(this).addClass('hasDatepicker');
        }
    });
})();

export default datepickerInitializer;
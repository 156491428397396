import $ from 'jquery';
import Modal from '../modules/modal';
import ajaxTokenInvoker from '../modules/ajaxTokenInvoker';

const clubMemberHandler = (() => {
    let fanBtn = $('.btn--fan');
    let memberBtn = $('.btn--member');
    let el;
    let options;
    let modal;
    let modal1;
    if (document.querySelector('[data-modal]')) {
        el = document.querySelector('[data-modal]');
        options = {
            selector: el,
            template: el.getAttribute('data-modal-url'),
            extra: el.getAttribute('data-modal-extra')
        };
        modal = new Modal(options);

        $('[data-modal]').on('click', function (evt) {
            evt.preventDefault();
            modal.open();
        });
    }

    if (document.querySelector('[data-modal1]')) {
        el = document.querySelector('[data-modal1]');
        options = {
            selector: el,
            template: el.getAttribute('data-modal-url1'),
            extra   : el.getAttribute('data-modal-extra1')
        };
        modal1 = new Modal(options);

        $('[data-modal1]').on('click', function (evt) {
            evt.preventDefault();
            modal1.open();
        });
    }	

    $(document).on('click', '[data-club-exit]', function (evt) {
        evt.preventDefault();
        let $form = $(evt.target).closest('[data-club-member-form]');
        let url = $form.data('clubMemberUrl');
        let token = document.querySelector('[data-token]').dataset.token;
        let teams = $.map($form.find('input[name="teams[]"]').serializeArray(), function (v, i) {
            return v.value;
        });
        let data = {
            status: false,
            _token: token,
            teams: teams
        };

        ajaxPublisher(url, data, (res) => {
            // todo refactor notifcations
            memberBtn.removeClass('btn--active');
            fanBtn.show(0);
            modal.close();
            status = false;

            let statusMessage = '';
            let statusClass = '';

            if (res.status == false) {
                statusMessage = 'Du bist jetzt nicht mehr Mitglied des Vereins!';
                statusClass = 'club-detail__notification--info';
            } else {
                statusMessage = 'Du bist jetzt Mitglied des Vereins';
                statusClass = 'club-detail__notification--success';
            }

            let $notification = $('body').find('.club-detail__notification');
            $notification
                .removeClass('club-detail__notification--success')
                .removeClass('club-detail__notification--info')
                .addClass(statusClass)
                .text(statusMessage);
            $notification.stop(true, false).slideDown();

            setTimeout(() => $notification.slideUp(), 2500);
        });
    });

    $(document).on('click', '[data-club-save]', function (evt) {
        evt.preventDefault();
        let $form = $(evt.target).closest('[data-club-member-form]');
        let url = $form.data('clubMemberUrl');
        let token = document.querySelector('[data-token]').dataset.token;
        let teams = $.map($form.find('input[name="teams[]"]').serializeArray(), function (v, i) {
            return v.value;
        });
        let status = $form.find('input[data-club-member-form-status-input]').prop('checked');
        let data = {
            status: true,
            _token: token,
            teams: teams
        };

        ajaxPublisher(url, data, (res) => {
            // todo refactor notifcations
            memberBtn.addClass('btn--active');
            fanBtn.hide();
            modal.close();
            status = false;

            let statusMessage = '';
            let statusClass = '';

            if (res.status == false) {
                statusMessage = 'Du bist jetzt nicht mehr Mitglied des Vereins!';
                statusClass = 'club-detail__notification--info';
            } else {
                statusMessage = 'Du bist jetzt Mitglied des Vereins';
                statusClass = 'club-detail__notification--success';
            }

            let $notification = $('body').find('.club-detail__notification');
            $notification
                .removeClass('club-detail__notification--success')
                .removeClass('club-detail__notification--info')
                .addClass(statusClass)
                .text(statusMessage);
            $notification.stop(true, false).slideDown();

            setTimeout(() => $notification.slideUp(), 2500);
        });
    });

    $(document).on('click', '[data-club-save]', function (evt) {
        evt.preventDefault();
    });

    const ajaxPublisher = (url, data, cb = () => {
    }) => {
        $.ajax(url, {
            method: 'POST',
            data: data,
            success: function (res) {
                cb(res);
            },
            error: function (err) {
                alert('Es ist ein Fehler aufgetreten.');
            }
        });
    };
})();

export default clubMemberHandler;
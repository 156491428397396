const editHandler = (() => {
    let $editBtn = $('[data-edit]');
    let $cancelBtn = $('[data-cancel]');
    let $deleteBtn = $('[data-delete]');
    let loaderMarkup = `<div data-loader class="loader__wrapper loader__wrapper--centered"><div class="loader loader--loading"></div><div class="loader loader--loading"></div></div>`;

    if ($editBtn) {
        $(document).on('click', '[data-edit]', (evt) => {
            evt.preventDefault();
            let $el = $(evt.target);
            let data = $el.data('edit');
            let content = $el.closest('[data-edit-wrapper]').find('[data-edit-content]');

            content.html(loaderMarkup);

            $.get(data.resource.url, (res) => {
                content.html(res);
            });

            $el.closest('[data-edit-wrapper]').find('[data-edit]').addClass('club-content__options-button--hidden');
            $el.closest('[data-edit-wrapper]').find('[data-cancel]').removeClass('club-content__options-button--hidden');
        });
    }

    if ($cancelBtn) {
        $(document).on('click', '[data-cancel]', (evt) => {
            evt.preventDefault();
            let $el = $(evt.target);
            let data = $el.data('cancel');
            let content = $el.closest('[data-edit-wrapper]').find('[data-edit-content]');

            content.html(loaderMarkup);

            $.get(data.resource.url, (res) => {
                content.html($(res).find('[data-edit-content]').html());

                $el.closest('[data-edit-wrapper]').find('[data-edit]').removeClass('club-content__options-button--hidden');
                $el.closest('[data-edit-wrapper]').find('[data-cancel]').addClass('club-content__options-button--hidden');
            });
        });
    }

    if ($deleteBtn) {
        $(document).on('click', '[data-delete]', (evt) => {
            evt.preventDefault();
            let confirmDelete = confirm('Möchtest du diesen Eintrag wirklich löschen?');
            let $el = $(evt.target);

            if (confirmDelete) {
                let data = $el.data('delete');
                let $matchingElements = $(`[data-delete*='${data.resource.type}'][data-delete*='${data.resource.id}']`);
                let wrapper = $matchingElements.closest('[data-edit-wrapper]');

                wrapper.html(loaderMarkup);

                $.ajax({
                    method: 'DELETE',
                    url: data.resource.url,
                    data: data.resource.id,
                    success: (res) => {
                        wrapper.fadeOut({
                            complete: () => {
                                wrapper.remove();
                            }
                        });
                    },
                    error: (err) => {
                    }
                });
            }
        });
    }


})();

export default editHandler;
const clubColorHandler = (() => {
    let $toggleHandle = $('[data-color-published]');
    if ($toggleHandle) {
        let token = $('[data-token]').data('token');
        let slug = $('[data-club-slug]').data('club-slug');
        $toggleHandle.click(function (evt) {
            let headerColor = $('#header_color').val();
            let titleColor = $('#title_color').val();
            $.ajax('/vereine/' + slug + '/color/update', {
                method : 'PATCH',
                data   : {
                    header_color: headerColor,
                    title_color : titleColor
                },
                success: function (res) {
                    console.log(res);
                },
                error  : function (err) {
                    console.log(err);
                }
            });
        });
    }
})();
export default clubColorHandler;


const tabbableMenu = (() => {
    let menuSelector = 'data-tabbable-menu';
    let linkSelector = 'a';
    let items = Array.from(document.querySelectorAll(`[${menuSelector}="0"] ${linkSelector}`));
    let childItems = Array.from(document.querySelectorAll(`[${menuSelector}="1"] ${linkSelector}`));
    let menuItems = Array.from(document.querySelectorAll(`[${menuSelector}="2"] ${linkSelector}`));

    const toggleVisibility = (elements) => {
        elements.forEach((element) => {
            element.classList.remove('tabbable-menu__page--active');
        });
    };

    const toggleActive = (items) => items.forEach((item) => item.parentNode.classList.remove('active'));

    if (items) {
        let linkedElements = [];
        items.forEach((item) => {
            let linkedElement = document.getElementById(item.getAttribute('href').substr(1));

            linkedElements.push(linkedElement);

            if (!item.parentNode.classList.contains('active')) {
                linkedElement.classList.remove('tabbable-menu__page--active');
            }

            item.addEventListener('click', (evt) => {
                evt.preventDefault();
                let element = evt.target;
                let href = element.getAttribute('href');
                let linkedElId = href.substr(1);
                let linkedElement = document.getElementById(linkedElId);


                toggleActive(items);
                toggleVisibility(linkedElements);

                element.parentNode.classList.add('active');
                linkedElement.classList.add('tabbable-menu__page--active')
            });
        });
    }

    if (childItems) {
        let linkedElements = [];
        childItems.forEach((item) => {
            let linkedElement = document.getElementById(item.getAttribute('href').substr(1));

            linkedElements.push(linkedElement);

            if (!item.parentNode.classList.contains('active')) {
                linkedElement.classList.remove('tabbable-menu__page--active')
            }

            item.addEventListener('click', (evt) => {
                evt.preventDefault();
                let element = evt.target;
                let href = element.getAttribute('href');
                let linkedElement = document.getElementById(href.substr(1));


                toggleActive(childItems);
                toggleVisibility(linkedElements);

                element.parentNode.classList.add('active');
                linkedElement.classList.add('tabbable-menu__page--active')
            });
        });
    }

    if (menuItems) {
        let linkedElements = [];
        menuItems.forEach((item) => {
            let linkedElement = document.getElementById(item.getAttribute('href').substr(1));

            linkedElements.push(linkedElement);

            if (!item.parentNode.classList.contains('active')) {
                linkedElement.classList.remove('tabbable-menu__page--active')
            }

            item.addEventListener('click', (evt) => {
                evt.preventDefault();
                let element = evt.target;
                let href = element.getAttribute('href');
                let linkedElement = document.getElementById(href.substr(1));


                toggleActive(menuItems);
                toggleVisibility(linkedElements);

                element.parentNode.classList.add('active');
                linkedElement.classList.add('tabbable-menu__page--active')
            });
        });
    }

})();

export default tabbableMenu;
const clubPublishHandler = (() => {
    let $toggleHandle = $('[data-club-published-checkbox]');
    let $publishBtn = $('[data-club-published-button]');
    let data = {
        published: false
    };

    if ($toggleHandle) {
        $toggleHandle.on('change', function (evt) {
            let $el = $(evt.target);
            let updateUrl = $el.data('update-url');
            data.published = $el.prop('checked');

            ajaxPublisher(updateUrl, data);
        });
    }

    if ($publishBtn) {
        $publishBtn.on('click', (evt) => {
            evt.preventDefault();
            let $el = $(evt.target);
            let url = $el.data('updateUrl');
            data.published = true;

            ajaxPublisher(url, data, (res) => {
                let $notification = $el.closest('.club-detail__notification');
                $notification
                    .toggleClass('club-detail__notification--warning club-detail__notification--success')
                    .text('Dein Verein ist jetzt öffentlich');

                setTimeout(() => $notification.slideUp(), 1500);
            });
        });
    }

    const ajaxPublisher = (url, data, cb = () => {
    }) => {
        $.ajax(url, {
            method: 'PATCH',
            data: data,
            success: function (res) {
                cb(res);
            },
            error: function (err) {
                alert('Es ist ein Fehler aufgetreten.');
            }
        });
    };

})();

export default clubPublishHandler;
import $ from 'jquery';
import axios from 'axios';

class Modal {
    constructor(options) {
        if (this.validateOptions(options)) {
            this.options = options;
        } else {
            this.log('Options must be a object.');
        }
        this.http = axios.create({
            headers: {
                'X-CSRF-TOKEN': document.querySelector('[data-token]').dataset.token,
                'X-Requested-With': 'XMLHttpRequest',
            }
        });

        this.modal = null;
        this.status = false;
    }

    log(msg) {
        this.log.color = 'background: #222; color: #bada55';
        console.log('%c Modal! ', this.log.color, msg);
    }

    validateOptions(options) {
        return (options !== null && typeof options === 'object');
    }

    loadTemplate(url) {
        this.http.get(url).then((res) => {
            let partial = document.createElement('div');
            partial.innerHTML = res.data;
            document.querySelector('body').appendChild(partial);
            this.modal = document.querySelector('#modal');
            this.modal.classList.add('modal__container--opend');
            this.bindings();
        });
    }

    open() {
        if (!this.status) {
            this.status = true;
            this.loadTemplate(this.options.template);
        }
    }

    close() {
        this.modal.classList.remove('modal__container--opend');
        this.modal.remove();
        this.status = false;
    }

    bindings() {
        let that = this;
        // Close
        document.querySelector('[data-modal-close]').addEventListener('click', function (evt) {
            evt.preventDefault();
            that.close();
        });
    }
}

export default Modal;
const flashMessageHandler = (() => {
    let flashMessage = $('.flash-message');
    if (flashMessage) {
        flashMessage.find('a').click(function (e) {
            e.preventDefault();
            flashMessage.slideUp();
            clearInterval(check);
        });
        let check = setInterval(() => {
            if (flashMessage) {
                clearInterval(check);
                setTimeout(() => flashMessage.slideUp(), 1500);
            }
        }, 7500);
    }
})();

export default flashMessageHandler;
export default function ajaxPager(url = '') {
    let $pager = $('[data-pager]');
    if ($pager.length > 0) {
        let pagerData = $pager.data('pagerContent');
        let $pagerHTML = $('[data-pager-html]');
        let $nextBtn = $('[data-pager-next]'),
            $prevBtn = $('[data-pager-previous]');
        let $loader = $('[data-loader]');
        $loader.show();
        url = url || pagerData.url;
        $.get(url, (res) => {
            $pagerHTML.html(res.data.rendered);

            $loader.hide();

            $nextBtn.off().click((evt) => {
                evt.preventDefault();
                if (res.next_page_url !== null) {
                    $loader.show();
                    ajaxPager(res.next_page_url);
                }
            });

            $prevBtn.off().click((evt) => {
                evt.preventDefault();
                if (res.previous_page_url !== null) {
                    $loader.show();
                    ajaxPager(res.previous_page_url);
                }
            });

            $('[data-insert-hyphen-async]').hyphenate('de');
        });
    }
};
const slickSliderInitializer = (() => {
    $('.hero-slider').slick({
        infinite: true,
        speed: 1250,
        autoplay: true,
        fade: true,
        draggable: false,
        autoplaySpeed: 7500,
        cssEase: 'linear',
        slidesToShow: 1,
        slidesToScroll: 1,
        arrows: false
    });
	
	$('.startpage-slider').slick({
		dots          : true,
		arrows        : true,
        infinite      : true,
		autoplay      : true,
        speed         : 1250,
        autoplaySpeed : (autoplaySpeed * 1000),
		slidesToShow  : 1,
        cssEase       : 'linear',
		slidesToScroll: 1,
		responsive    : [
			{
				breakpoint: 1024,
				settings  : {
					slidesToShow  : 1,
					slidesToScroll: 1,
                    infinite      : true,
					dots          : true,
					arrows        : true,
				}
			},
			{
				breakpoint: 750,
				settings  : {
					slidesToShow  : 1,
                    infinite      : true,
					slidesToScroll: 1
				}
			},
			{
				breakpoint: 480,
				settings  : {
					slidesToShow  : 1,
                    infinite      : true,
					slidesToScroll: 1
				}
			}
		]
	});

	$('.regular').slick({
		dots          : true,
		arrows        : true,
        infinite      : true,
		slidesToShow  : 3,
		slidesToScroll: 3,
		responsive    : [
			{
				breakpoint: 1024,
				settings  : {
					slidesToShow  : 3,
					slidesToScroll: 3,
					infinite      : true,
					dots          : true
				}
			},
			{
				breakpoint: 750,
				settings  : {
					slidesToShow  : 2,
                    infinite      : true,
					slidesToScroll: 2
				}
			},
			{
				breakpoint: 480,
				settings  : {
					slidesToShow  : 1,
                    infinite      : true,
					slidesToScroll: 1
				}
			}
		]
    });

    /*$('.news-slider').slick({
        dots          : false,
        arrows        : true,
        infinite      : false,
        autoplay      : false,
        speed         : 1250,
        autoplaySpeed : 7500,
        slidesToShow  : 1,
        cssEase       : 'linear',
        slidesToScroll: 1,
        customPaging  : function (slider, i) {
            var thumb = $(slider.$slides[i]).data();
            return '<a>' + i + '</a>';
        },
        prevArrow     : $(".news-prev"),
        nextArrow     : $(".news-next"),
        responsive    : [
            {
                breakpoint: 1024,
                settings  : {
                    slidesToShow  : 1,
                    slidesToScroll: 1,
                    infinite      : true,
                    dots          : true,
                    arrows        : true,
                }
            },
            {
                breakpoint: 750,
                settings  : {
                    slidesToShow  : 1,
                    infinite      : true,
                    slidesToScroll: 1
                }
            },
            {
                breakpoint: 480,
                settings  : {
                    slidesToShow  : 1,
                    infinite      : true,
                    slidesToScroll: 1
                }
            }
        ]
    });*/
	
})();

export default slickSliderInitializer;
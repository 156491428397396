export default function pageableMenu() {
    let menuDataAttr = 'data-pageable-menu',
        parentSelector = 'li',
        elementSelector = 'a',
        linkData = document.querySelectorAll(`[${menuDataAttr}] > ${parentSelector} > ${elementSelector}`),
        success = false;

    let menu = {
        nodes: {
            links: Array.from(linkData),
            contents: []
        }
    };

    if (menu.nodes.links && menu.nodes.links.length > 0) {

        let toggleElements = (elementList, currentElement, href) => {
            if (currentElement !== null) {
                currentElement.classList.add('pageable-menu__page--active');
            }
            elementList.filter((element) => element && element.id && element.id !== href.substr(1))
                .map((element) => element.classList.remove('pageable-menu__page--active'));
        };


        menu.nodes.links.forEach((item, index) => {
            let href = item.getAttribute('href'),
                contentId = href.substr(1),
                contentEl = document.getElementById(contentId);
            menu.nodes.contents = [...menu.nodes.contents, contentEl];

            if (!index) {
                contentEl.classList.add('pageable-menu__page--active');
            }

            if (href === location.hash) {
                item.classList.add('active');
                success = true;
            } else if (!location.hash) {
                menu.nodes.links[0].classList.add('active');
            }

            item.addEventListener('click', (e) => {
                let href = e.target.getAttribute('href');
                menu.nodes.links.forEach((itx) => itx.classList.remove('active'));
                e.preventDefault();
                e.target.classList.add('active');
                toggleElements(menu.nodes.contents, contentEl, href);
                // @todo store state object
                history.pushState({}, null, href);
            });
        });

        (init => {
            if (!success) {
                menu.nodes.links[0].classList.add('active');
                toggleElements(menu.nodes.contents, document.getElementById(menu.nodes.links[0].getAttribute('href').substr(1)), menu.nodes.links[0].getAttribute('href'))
            } else {
                toggleElements(menu.nodes.contents, null, location.hash)
            }
        })();

    }

};
const newsEditor = (() => {
    let adminCSRF = document.querySelector('#admin-news-csrf');
    if (adminCSRF) {
        CKEDITOR.replace('content', {
            filebrowserUploadUrl: `/admin/upload-news-image?_token=${adminCSRF.value}`
        });

        CKEDITOR.replace('steckbrief', {
            filebrowserUploadUrl: `/admin/upload-news-image?_token=${adminCSRF.value}`
        });
    }
})();

export default newsEditor;
import 'babel-polyfill';
import $ from 'jquery';
import ajaxPager from './modules/ajaxPager';
import pageableMenu from './modules/pageableMenu';
import './modules/newsPublishHandler';
import './modules/spitzensportPublishHandler';
import './modules/newsSoftDeleteHandler';
import './modules/autoCompletion';
import './modules/flashMessageHandler';
import './modules/newsSlugHandler';
import './modules/clubPublishHandler';
import './modules/clubFanHandler';
import './modules/clubMemberHandler';
import './modules/bandageSubscriberHandler';
import './modules/tabbableMenu';
import './modules/asyncDataLoader';
import './modules/editHandler';
import './modules/imageCropper';
import './modules/clubEditHandler';
import './modules/sportHandler';
import './modules/newsEditor';
import './modules/newsDataTable';
import './modules/votesDataTable';
import './modules/mobileMenu';
import './modules/slickSliderInitializer';
import './modules/ajaxTokenInvoker';
import './modules/datepickerInitializer';
import './modules/popover';
import './modules/newMember';
import './modules/toTop';
import './modules/smoothScrollForAnchors';
import './modules/networkSearch';
import './modules/DashboardClubs';
import './modules/clubColorHandler';
import './modules/campaignPublishHandler';
import './modules/bandageColorHandler';

require("guillotine");

(function ($) {
    //global vars intended
    var currentSize = (window.getComputedStyle(document.getElementById('body'), '::after').getPropertyValue('content')).replace(/"/g, '');
    var isTouchDevice = ('ontouchstart' in window) || window.DocumentTouch && document instanceof DocumentTouch ? true : false;

    $(window).resize(function () {
        currentSize = "" + (window.getComputedStyle(document.getElementById('body'), '::after').getPropertyValue('content')).replace(/"/g, '');
    });

    ajaxPager();
    pageableMenu();

    $('[data-insert-hyphen]').hyphenate('de');

    function generalDataOnError() {
        if (location.hash === '#allgemeine-daten' && $('.flash-message--error').length) {
            $('.club-content__edit-button--basedata').trigger('click');
        }
    }

    generalDataOnError();

    var $formRegister = $('#form_register');
    if( $formRegister.length > 0 ) {
        var $birthdayDay = $formRegister.find('#birthday-day > option:first');
        var $birthdayMonth = $formRegister.find('#birthday-month > option:first');
        var $birthdayYear = $formRegister.find('#birthday-year > option:first');

        if( $birthdayDay.length > 0 ) {
            $birthdayDay.attr('disabled', true);
        }

        if( $birthdayMonth.length > 0 ) {
            $birthdayMonth.attr('disabled', true);
        }

        if( $birthdayYear.length > 0 ) {
            $birthdayYear.attr('disabled', true);
        }
    }

    function init() {
        window.addEventListener('scroll', function (e) {
            var distanceY = window.pageYOffset || document.documentElement.scrollTop,
                shrinkOn = 150,
                header = document.querySelector(".navi-wrapper");
            var flash;
            if ($('.page').hasClass('flash-message')) {
                flash = document.querySelector(".flash-message");
            }
            if (distanceY > shrinkOn) {
                classie.add(header, "smaller");
                if (flash) {
                    classie.add(flash, "flash-message-smaller");
                }
            } else {
                if (classie.has(header, "smaller")) {
                    classie.remove(header, "smaller");
                }
                if (classie.has(header, "flash-message-smaller")) {
                    classie.remove(flash, "flash-message-smaller");
                }
            }
        });
    }

    window.onload = init();	

})(jQuery);
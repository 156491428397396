$(document).ready(function () {
// Initializing arrays with city names.
    var Vereine = [{
        display: "", value: ""
    }
    ];
    var Aktivitaet = [{
        display: "Letzte 24 h", value: "24h"
    },
        {display: "Letzte 7 Tage", value: "7Tage"},
        {display: "Letzte 30 Tage", value: "30Tage"},
        {display: "Letzte 90 Tage", value: "90Tage"}
    ];
    var Intensitaet = [{
        display: "Logo", value: "Logo"
    },
        {display: "Header", value: "Header"},
        {display: "Infos", value: "Infos"},
        {display: "Teams", value: "Teams"},
        {display: "Weblinks", value: "Weblinks"},
        {display: "Erfolge", value: "Erfolge"},
        {display: "Posts", value: "Posts"}];

// Function executes on change of first select option field.
    $("#abschnitt").change(function () {
        var select = $("#abschnitt option:selected").val();

        switch (select) {
            case "0":
                subabschnitt(Vereine, "Vereine");
                break;
            case "1":
                subabschnitt(Aktivitaet, "Aktivitaet");
                break;
            case "2":
                subabschnitt(Intensitaet, "Intensitaet");
                break;
            default:
                $("#subabschnitt").empty();
                $("#subabschnitt").append("<option>--Select--</option>");
                break;
        }
    });

// Function To List out subcategories in Second Select tags
    function subabschnitt(arr, sub) {
        $("#subabschnitt").empty(); //To reset subcategories
        if (sub == "Vereine") {
            $("#subabschnitt").append("<option>Keine Subkategorie</option>");
        }
        else {
            $("#subabschnitt").append("<option>Subkategorie wählen</option>");
            $(arr).each(function (i) { //to list cities
                $("#subabschnitt").append("<option value=" + arr[i].value + ">" + arr[i].display + "</option>")
            });
        }
    }
});

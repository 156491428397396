const spitzensportPublishHandler = function () {
  var $toggleHandle = $('[data-toggle-published-spitzensport]');
  if ($toggleHandle) {
    var token = $('[data-token]').data('token');
    $toggleHandle.click(function (evt) {
      var spitzensportId = $(evt.target).data('spitzensportId');

      $.ajax('/admin/spitzensport/toggle-visibility', {
        method: 'PATCH',
        data: {
          id: spitzensportId
        },
        success: function success(res) {
          console.log(res);
        },
        error: function error(err) {
          alert('Es ist ein Fehler aufgetreten.');
        }
      });
    });
  }
}();

export default spitzensportPublishHandler;
const mobileMenu = (() => {
    $('#mobile-menu').click(function () {
        $(this).find('i').toggleClass('fa-bars').toggleClass('fa-times');
        let state = $('#menu-wrapper').hasClass('menu-wrapper--active');
        if (state) {
            $('#menu-wrapper').removeClass('menu-wrapper--opened');
            $('#menu-wrapper').removeClass('menu-wrapper--active');
        } else {
            $('#menu-wrapper').addClass('menu-wrapper--active');
            setTimeout(function () {
                $('#menu-wrapper').addClass('menu-wrapper--opened');
            }, 600);
        }
    });

    $('[data-submenu-trigger]').click(function (event) {
        event.preventDefault();
        let state = $(this).hasClass('menu__item-link--focus');
        $('[data-submenu-trigger]').removeClass('menu__item-link--focus');
        $(this).toggleClass('menu__item-link--focus', !state);
        if (state) {
            $(this).blur();
        }
    });
})();

export default mobileMenu;
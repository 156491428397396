const asyncDataLoader = (() => {
    let $loadMore = $('[data-load-more]');

    if ($loadMore && $loadMore.length > 0) {
        $loadMore.each((key, val) => {
            let $el = $(val);
            let $loadMoreBtn = $el.find($('[data-load-more-button]'));
            let $contentSelector = $el.find($('[data-load-more-html]'));
            let $content = $el.find($contentSelector);
            let $loader = $el.find($('[data-loader]'));
            let data = $el.data('loadMore');
            let initial = true;

            const fetchData = (url = data.url) => {
                $.get(url, (res) => {
                    if (typeof res.data !== 'undefined' && typeof res.data.rendered !== 'undefined') {
                        if (initial) {
                            $content.html(res.data.rendered);
                            initial = false;
                        } else {
                            $content.append(res.data.rendered);
                        }

                        if ($('.club-post__image-slider')) {
                            $('.club-post__image-slider').not('.slick-initialized').slick({
                                prevArrow: '<button type="button" class="slick-prev"></button>',
                                nextArrow: '<button type="button" class="slick-next"></button>',
                                autoplay: false,
                                arrows: true,
                                responsive: [
                                    {
                                        breakpoint: 992,
                                        settings: {
                                            autoplay: true,
                                            arrows: false
                                        }
                                    }
                                ]
                            });
                        }

                        $loader.hide();

                        if (res.next_page_url === null) {
                            $loadMoreBtn.fadeOut();
                        }

                        $loadMoreBtn.off().click((evt) => {
                            evt.preventDefault();
                            if (res.next_page_url !== null) {
                                $loader.show();
                                fetchData(res.next_page_url);
                            }
                        });
                    } else {
                        $loader.hide();
                    }
                });

            };

            fetchData();
        });

    }
})();

export default asyncDataLoader;
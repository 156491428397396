import guillotine from 'guillotine';

const popover = (() => {

    init();
    var that;
    var reader;
    var oldSrc;
    var runOnce = false;

    function errorHandler(evt) {
        switch (evt.target.error.code) {
            case evt.target.error.NOT_FOUND_ERR:
                alert('File Not Found!');
                break;
            case evt.target.error.NOT_READABLE_ERR:
                alert('File is not readable');
                break;
            case evt.target.error.ABORT_ERR:
                break; // noop
            default:
                alert('An error occurred reading this file.');
        }
    }

    function bindings() {
        $('*[data-call-popover]').on('click', function () {
            var el = $(this).data('call-popover');
            var selector = $('*[data-popover="' + el + '"]');
            selector.addClass('popover--opend');
            $(this).addClass('animation__zoom-in');
        });
    }

    function init() {
        bindings();
    }

    function handleImageSelect(evt) {
        that = this;
        if (!runOnce) {
            oldSrc = $('.' + $(that).attr('data-image')).attr('src');
            runOnce = true;
        }
        reader = new FileReader();
        reader.onerror = errorHandler;
        reader.onabort = function (e) {
            alert('File read cancelled');
        };
        reader.onload = function (e) {
            $('*[for="' + $(that).attr('name') + '"]').removeClass('btn--icon-switch').addClass('btn--dark-blue');
            $('.popover__controlls--' + $(that).attr('name')).removeClass('popover__controlls--hidden');
            $('.' + $(that).attr('data-image')).attr('src', e.target.result);
            $('.' + $(that).attr('data-image')).guillotine({
                width: $(that).attr('data-width'),
                height: $(that).attr('data-height'),
                eventOnChange: 'guillotinechange'+$(that).attr('name')
            });
            console.log($(that).attr('data-width'));
            console.log($(that).attr('data-height'));

            $('.' + $(that).attr('data-image')).guillotine('center');
            $('.' + $(that).attr('data-image')).on('guillotinechange'+$(that).attr('name'), function (ev, data, action) {
                $('#' + $(that).attr('data-crop-props')).val(JSON.stringify(data));
            });

            document.getElementById($(that).attr('data-zoom-in')).addEventListener('click', zoomIn, false);
            document.getElementById($(that).attr('data-zoom-out')).addEventListener('click', zoomOut, false);
        };

        // Read in the image file as a binary string.
        reader.readAsDataURL(evt.target.files[0]);
    }

    function zoomIn(evt) {
        evt.preventDefault();
        $('.' + $(that).attr('data-image')).guillotine('zoomIn');
    }

    function zoomOut(evt) {
        evt.preventDefault();
        $('.' + $(that).attr('data-image')).guillotine('zoomOut');
    }

    function cancel(evt) {
        evt.preventDefault();
        $('.' + $(that).attr('data-image')).guillotine('remove');
        $('.' + $(that).attr('data-image')).attr('src', oldSrc);
        $('*[for="' + $(that).attr('name') + '"]').addClass('btn--icon-switch').removeClass('btn--dark-blue');
        $('.popover__controlls--' + $(that).attr('name')).addClass('popover__controlls--hidden');
        let el = $(this).closest('.popover');
        let selector = $('*[data-call-popover="' + el.data('popover') + '"]');
        selector.removeClass('animation__zoom-in');
        el.removeClass('popover--opend');
    }

    if (document.getElementById('avatar')) {
        document.getElementById('avatar').addEventListener('change', handleImageSelect, false);
    }
    if (document.getElementById('header')) {
        document.getElementById('header').addEventListener('change', handleImageSelect, false);
    }
    if (document.getElementById('profile-avatar-edit__form-cancel-action-button')) {
        document.getElementById('profile-avatar-edit__form-cancel-action-button').addEventListener('click', cancel, false);
    }
    if (document.getElementById('profile-header-edit__form-cancel-action-button')) {
        document.getElementById('profile-header-edit__form-cancel-action-button').addEventListener('click', cancel, false);
    }
})();

export default popover;
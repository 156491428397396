const votesDataTable = (() => {
    $('#votes-table').DataTable({
        language: {
            search: 'Suche',
            info: 'Zeige _START_ bis _END_ von _TOTAL_ Einträgen',
            paginate: {
                first: 'Erste Seite',
                last: 'Letzte Seite',
                previous: 'Vorherige',
                next: 'Nächste',
                thousands: '.',
                decimal: ','
            },
        },
        order: [[0, "desc"]]
    });
})();

export default votesDataTable;
const newsSoftDeleteHandler = (() => {
    let $toggleHandle = $('[data-toggle-delete]');
    if ($toggleHandle) {
        let token = $('[data-token]').data('token');
        $toggleHandle.click(function (evt) {
            let deleteConfirm = confirm('Möchtest du diese Aufzeichnung wirklich löschen?');
            let recordId = $(this).data('recordId');
            evt.preventDefault();

            if (deleteConfirm) {
                $.ajax($(this).data('delete-path'), {
                    method: 'PATCH',
                    data: {
                        id: recordId
                    },
                    success: function (res) {
                        console.log(res);
                    },
                    error: function (err) {
                    }
                });
            }

        });
    }

})();

export default newsSoftDeleteHandler;
const clubFanHandler = (() => {
    let $publishBtn = $('[data-club-fan-button]');
    let buttonActiveClass = 'btn--active';

    if ($publishBtn) {
        // initially set the class
        if ($publishBtn.data('clubFanStatus') == true) {
            $publishBtn.addClass(buttonActiveClass);
        }

        $publishBtn.on('click', (evt) => {
            evt.preventDefault();
            let $el = $(evt.target);
            $el.blur();

            let status = ($el.data('clubFanStatus') == true) ? false : true;
            $el.data('clubFanStatus', status);

            let url = $el.data('clubFanUrl');
            let token = $el.closest('[data-token]').data('token');
            let data = {
                status: status,
                token: token
            };

            ajaxPublisher(url, data, (res) => {
                let statusMessage = '';
                let $notification = $('body').find('.club-detail__notification');
                if (res.status == true) {
                    statusMessage = 'Du bist jetzt Fan des Vereins!';
                    $notification
                        .removeClass('club-detail__notification--info')
                        .addClass('club-detail__notification--success')
                        .text(statusMessage);
                    $el.addClass(buttonActiveClass);
                } else {
                    statusMessage = 'Schade, dass du kein Fan mehr bist!';
                    $notification
                        .removeClass('club-detail__notification--success')
                        .addClass('club-detail__notification--info')
                        .text(statusMessage);
                    $el.removeClass(buttonActiveClass);
                }

                $notification.slideDown();

                setTimeout(() => $notification.slideUp(), 2500);
            });
        });
    }

    const ajaxPublisher = (url, data, cb = () => {
    }) => {
        $.ajax(url, {
            method: 'POST',
            data: data,
            success: function (res) {
                cb(res);
            },
            error: function (err) {
                alert('Es ist ein Fehler aufgetreten.');
            }
        });
    };

})();

export default clubFanHandler;
const newsPublishHandler = (() => {
    let $toggleHandle = $('[data-toggle-published]');
    if ($toggleHandle) {
        let token = $('[data-token]').data('token');
        $toggleHandle.click(function (evt) {
            let newsId = $(evt.target).data('newsId');

            $.ajax('/admin/news/toggle-visibility', {
                method: 'PATCH',
                data: {
                    id: newsId
                },
                success: function (res) {
                    console.log(res);
                },
                error: function (err) {
                    alert('Es ist ein Fehler aufgetreten.');
                }
            });
        });
    }
})(); 
/*
export default newsPublishHandler;

var spitzensportPublishHandler = function () {
    var $toggleHandle = $('[data-toggle-published-spitzensport]');
    if ($toggleHandle) {
        var token = $('[data-token]').data('token');
        $toggleHandle.click(function (evt) {
            var spitzensportId = $(evt.target).data('spitzensportId');

            $.ajax('/admin/spitzensport/toggle-visibility', {
                method: 'PATCH',
                data: {
                    id: spitzensportId
                },
                success: function success(res) {
                    console.log(res);
                },
                error: function error(err) {
                    alert('Es ist ein Fehler aufgetreten.');
                }
            });
        });
    }
  }();

  exports.default = spitzensportPublishHandler;
  */
export default newsPublishHandler;
const clubEditHandler = function () {
    var editClubSelector = '[data-club-editable]';
    var sectionSelector = '[data-section-editable]';
    var editButtonSelector = '.club-content__edit-button';
    var cancelEditButtonSelector = '.club-content__form-cancel-action-button';
    var saveEditButtonSelector = '.club-content__form-save-action-button';
    var editAreaSelector = '.club-content__edit-area';
    var viewAreaSelector = '.club-content__view-area';

    var $editClubHandle = $(editClubSelector);
    if ($editClubHandle) {
        $editClubHandle.find(sectionSelector).each(function () {
            $(this).find(editAreaSelector).hide();
        });

        $(editClubSelector).on('click', editButtonSelector, function (e) {
            // e.preventDefault();
            var $section = $(this).closest(sectionSelector);
            $section.find(viewAreaSelector).hide();
            $section.find(editButtonSelector).hide();
            $section.find(editAreaSelector).show();
        });

        $(editClubSelector).on('click', cancelEditButtonSelector, function (e) {
            e.preventDefault();
            var $section = $(this).closest(sectionSelector);
            $section.find(viewAreaSelector).show();
            $section.find(editButtonSelector).show();
            $section.find(editAreaSelector).hide();
        });
    }
}();

export default clubEditHandler;
const ajaxTokenInvoker = (() => {
    $.ajaxPrefilter(function (options, originalOptions, xhr) {
        let token = $('[data-token]').data('token');

        if (token) {
            return xhr.setRequestHeader('X-CSRF-TOKEN', token);
        }
    });
})();

export default ajaxTokenInvoker;
const campaignPublishHandler = (() => {
    let $toggleHandle = $('[data-toggle-campaign-published]');
    if ($toggleHandle) {
        let token = $('[data-token]').data('token');
        $toggleHandle.click(function (evt) {
            let campaignId = $(evt.target).data('campaignId');

            $.ajax('/admin/campaign/toggle-visibility', {
                method: 'PATCH',
                data: {
                    id: campaignId
                },
                success: function (res) {
                    console.log(res);
                },
                error: function (err) {
                    alert('Es ist ein Fehler aufgetreten.');
                }
            });
        });
    }
})(); 

export default campaignPublishHandler;
/**
 * @deprecated 
 * use utils/Select2Initializer.js
 */
const sportHandler = (() => {
    $('[data-select-sports]').each(function () {
        $(this).select2({
            language: 'de',
            tags: true,
            createTag: function (params) {
                return {
                    id: params.term,
                    text: params.term
                }
            },
            ajax: {
                url: $(this).data('sports-url'),
                dataType: 'json',
                delay: 250,
                data: function (params) {
                    return {
                        search: params.term
                    };
                },
                processResults: function (data, params) {
                    var results = $.map(data.data, function (val, i) {
                        return {id: val['id'], text: val['title']};
                    });

                    var sorter = function (a, b) {
                        var input_string = params.term;
                        return Levenshtein.get(a.text, input_string) - Levenshtein.get(b.text, input_string);
                    }
                    results.sort(sorter);

                    return {
                        results: results
                    };
                },
                cache: true
            },
            escapeMarkup: function (markup) {
                return markup;
            },
            minimumInputLength: 1
        });
    });
})();

export default sportHandler;